import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  Toggle,
  InputGroup,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Section from '../Section'
import Loading from '../Loading'
import NotFound from '../NotFound'
import GoogleButton from './GoogleButton'

import { updateGeofenceSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Edit = ({ id, admin }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.geofences.EDIT, {
    variables: { id },
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const [updateGeofence] = useMutation(mutations.geofences.UPDATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      ...updateGeofenceSchema.cast(values),
      id,
      status: values.status ? 'ACTIVE' : 'INACTIVE',
      location: {
        type: 'Point',
        coordinates: [values.longitude, values.latitude],
      },
    }
    delete variables.longitude
    delete variables.latitude

    try {
      await updateGeofence({ variables })
      setSuccess('Zone was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.geofence) return <NotFound noMargin />

  return (
    <>
      <Section
        heading={`${admin ? 'Update' : 'Display'} Zone`}
        text="Give points to your loyal customers."
      >
        <Card>
          {loading
            ? <Loading />
            : (
              <Form
                initialValues={{
                  name: get(data, 'geofence[0].name', ''),
                  radius: get(data, 'geofence[0].radius', ''),
                  latitude: get(data, 'geofence[0].location.coordinates[1]', ''),
                  longitude: get(data, 'geofence[0].location.coordinates[0]', ''),
                  status: get(data, 'geofence[0].geofence_status.status', '') === 'ACTIVE',
                  points: get(data, 'geofence[0].points', ''),
                  refreshRate: get(data, 'geofence[0].refresh_rate', ''),
                }}
                validationSchema={updateGeofenceSchema}
                onSubmit={onSubmit}
                submitLabel="Update Zone"
                error={error}
                success={success}
                canSubmit={admin}
              >
                <FormSection>
                  <TextInput name="name" placeholder="Enter a name to help you identify this zone (only you will see this)" label="Zone Name" disabled={!admin} />
                  <Toggle name="status" label={['Active', 'Inactive']} disabled={!admin} />
                </FormSection>

                <FormSection
                  heading="Location"
                  text="Place your zone where your customers are. Typically, that will
                be your primary place of business. A wider zone can entice people visiting
                neighboring businesses."
                >
                  <TextInput name="radius" placeholder="5 (meters)" type="number" label="Radius in meters" disabled={!admin} />
                  <InputGroup>
                    <TextInput name="latitude" placeholder="26.209698" type="number" label="Latitude" step="any" disabled={!admin} />
                    <TextInput name="longitude" placeholder="-98.233922" type="number" label="Longitude" step="any" disabled={!admin} />
                    <GoogleButton
                      lat={get(data, 'geofence[0].location.coordinates[1]', '')}
                      lng={get(data, 'geofence[0].location.coordinates[0]', '')}
                    />
                  </InputGroup>
                </FormSection>

                <FormSection
                  heading="Points"
                  text="Points are awarded to your customers whenever they cross into your zone. To
                keep customers from being overly awarded you must specify a refresh rate in
                minutes."
                >
                  <InputGroup>
                    <TextInput name="points" placeholder="5 (on zone entry)" type="number" label="Points Awarded on Entry" disabled={!admin} />
                    <TextInput name="refreshRate" placeholder="300 (minutes)" type="number" label="Refresh Rate" disabled={!admin} />
                  </InputGroup>
                </FormSection>
              </Form>
            )
        }
        </Card>
      </Section>
    </>
  )
}

export default Edit
