import { gql } from 'apollo-boost'

const ME = gql`
query Me($authId: String!) {
  user(where: { auth_id: {_eq: $authId} }) {
    id
    accounts {
      account {
        id
        name
      }
    }
  }
}
`

const SELECT = gql`
  {
    account {
      id
      name
    }
  }
`

const BUSINESS = gql`
  query Account($accountId: uuid!) {
    account(where: { id: { _eq: $accountId } }) {
      id
      name
      description
      cta_text
      cta_url
      cta_image
      geofence_limit
      reward_limit
    }
  }
`

const USER_BUSINESS = gql`
  query User($id: uuid!) {
    user(where: { id: { _eq: $id } }) {
      id
      accounts {
        account {
          id
          name
          description
          cta_text
          cta_url
          cta_image
          geofence_limit
          reward_limit
        }
      }
    }
  }
`

const ACCOUNT_SELECT = gql`
{
  account {
    id
    name
    type
  }
}
`

const users = {
  ME,
  SELECT,
  BUSINESS,
  ACCOUNT_SELECT,
  USER_BUSINESS,
}

export default users
