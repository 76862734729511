import React from 'react'
import { Box } from 'rebass'
import { css } from 'styled-components/macro'

const Container = ({ bordered, ...others }) => (
  <Box
    width="100%"
    m="0 auto"
    px={[4, 4, 5]}
    css={css`
      max-width: 1024px;   
      ${bordered && css`
        border-top: 1px solid #eff2f7;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(239, 242, 247);
      `}
    `}
    {...others}
  />
)

export default Container
