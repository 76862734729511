import React from 'react'
import { Flex } from 'rebass'
import { css } from 'styled-components/macro'

import googleMaps from '../../assets/img/google-maps.png'

const GoogleButton = ({ lat, lng }) => (
  <Flex
    as="a"
    alignSelf={['flex-start', 'flex-end']}
    css={css`
      height: 60px;
      width: 120px;
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
    `}
    href={`https://maps.google.com/?q=${lat},${lng}&ll=${lat},${lng}&z=11`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      css={css`
      height: 60px;
      width: 60px;
      `}
      src={googleMaps}
      alt="view on map"
    />
  </Flex>
)

export default GoogleButton
