import React from 'react'
import { css } from 'styled-components/macro'
import { Card, Heading, Text } from 'rebass'

const FormSection = ({
  heading,
  text,
  bordered,
  children,
  ...others
}) => (
  <Card
    css={css`
      ${({ theme }) => (css`
        /* Remove bottom margin from last field */
        & > *:last-child {
          margin-bottom: 0;
        }

        /* Remove bottom margin from last field inside InputGroup */
        & > *:last-child > *:last-child {
          margin-bottom: 0;
        }

        /* Remove bottom margin from ALL fields inside InputGroup on
           desktop, label sometimes were affected so be sure to exclude them */
        @media (min-width: ${theme.breakpoints[0]}) {
          & > *:last-child > *:not(label) {
            margin-bottom: 0;
          }
        }
      `)}
    `}
    mb={4}
    border={bordered && '1px solid #e0e6ed'}
    p={bordered && 3}
    borderRadius={3}
    {...others}
  >
    {heading && <Heading as="h3" mb={3} fontSize={3}>{heading}</Heading>}
    {text && <Text color="textLight" mb={4}>{text}</Text>}
    {children}
  </Card>
)

export default FormSection
