import {
  object,
  string,
  number,
  boolean,
  array,
} from 'yup'

const email = string().email('Please provide a valid email address').required('Email address is a required field')
const password = string().required('Password is a required field').min(6, 'Password must be at least 6 characters in length')
const name = string().required('Name is required')
const description = string().required('Description is required')
const geofenceLimit = number().required('Zone limit is required').min(1, 'Limit must be greater than 0').integer('Limit must be an integer')
const rewardLimit = number().required('Reward limit is required').min(1, 'Limit must be greater than 0').integer('Limit must be an integer')
const radius = number().required('Radius is a required field').moreThan(0).integer('Radius must be an integer')
const latitude = number().required('Latitude is a required field')
const longitude = number().required('Longitude is a required field')
const points = number().required('Points is a required field').moreThan(0).integer('Points must be an integer')
const refreshRate = number().required('Refresh rate is a required field').moreThan(0).integer('Refresh rate must be an integer')
const status = boolean()
const admin = boolean()
const account = string().nullable()
const ctaText = string().required('Call to action is a required field')
const ctaUrl = string().url().required('URL is a required field')
const ctaImage = string().url().required('Image URL is a required field')
const type = string().required('Type is a required')

const passwordResetSchema = object().shape({
  email,
})

const signInSchema = object().shape({
  email,
  password,
})

const createGeofenceSchema = object().shape({
  name,
  radius,
  latitude,
  longitude,
  status,
  points,
  refreshRate,
  account,
})

const updateGeofenceSchema = object().shape({
  name,
  radius,
  latitude,
  longitude,
  status,
  points,
  refreshRate,
})

const createRewardSchema = object().shape({
  name,
  description,
  points,
  status,
  type,
  account,
})

const updateRewardSchema = object().shape({
  name,
  description,
  points,
  status,
})

const updateRedemptionSchema = object().shape({
  status,
})

const insertUserSchema = object().shape({
  email,
  name,
  admin,
})

const updateUserSchema = object().shape({
  email,
  name,
  admin,
})

const updateGeneralSchema = object().shape({
  name,
})

const updateCredentialsSchema = object().shape({
  email,
})

const updateAccountSchema = object().shape({
  name,
  description,
  geofenceLimit,
  rewardLimit,
  ctaText,
  ctaUrl,
  ctaImage,
})

const updateAccountSchemaNoLimit = object().shape({
  name,
  description,
  ctaImage,
})

const updateAccountCTA = object().shape({
  ctaText,
  ctaUrl,
})

const listEventsSchema = object().shape({
  geofences: array().min(1, 'Select at least one zone').required('Zone is required'),
  startDate: object().required('Start date is a required').nullable(),
  endDate: object().required('End date is a required').nullable(),
})

export {
  passwordResetSchema,
  signInSchema,
  createGeofenceSchema,
  updateGeofenceSchema,
  createRewardSchema,
  updateRewardSchema,
  updateRedemptionSchema,
  insertUserSchema,
  updateUserSchema,
  updateGeneralSchema,
  updateCredentialsSchema,
  updateAccountSchema,
  updateAccountSchemaNoLimit,
  updateAccountCTA,
  listEventsSchema,
}
