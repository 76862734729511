import { gql } from 'apollo-boost'

const INSERT = gql`
  mutation InsertUser(
    $email: String!
    $name: String!
    $role: String!
    $account: String!
  ) {
    insert_user(
      objects: {
        email: $email
        name: $name
        role: $role
        accounts: {
          data: {
            account: {
              data: {
                name: $account
                description: "This is my shiny new account :)"
                type: "BUSINESS"
              }
            }
          }
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateUser(
    $id: uuid!
    $email: String!
    $name: String!
    $role: String!
  ) {
    update_user(
      where: {
        id: { _eq: $id }
      }
      _set: {
        email: $email
        name: $name
        role: $role
      }
    ) {
      returning {
        id
      }
    }
  }
`

const ACCOUNT = gql`
  mutation UpdateAccount(
    $id: uuid!
    $name: String
    $ctaImage: String
    $description: String
  ) {
    update_account(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        cta_image: $ctaImage
      }
    ) {
      returning {
        id
      }
    }
  }
`

const CTA = gql`
  mutation UpdateAccount(
    $id: uuid!
    $ctaText: String
    $ctaUrl: String
  ) {
    update_account(
      where: { id: { _eq: $id } }
      _set: {
        cta_text: $ctaText
        cta_url: $ctaUrl
      }
    ) {
      returning {
        id
      }
    }
  }
`

const USERS_ACCOUNT = gql`
  mutation UpdateAccount(
    $id: uuid!
    $name: String
    $description: String
    $ctaText: String
    $ctaUrl: String
    $ctaImage: String
    $geofenceLimit: Int
    $rewardLimit: Int
  ) {
    update_account(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        cta_text: $ctaText
        cta_url: $ctaUrl
        cta_image: $ctaImage
        geofence_limit: $geofenceLimit
        reward_limit: $rewardLimit
      }
    ) {
      returning {
        id
      }
    }
  }
`

const users = {
  INSERT,
  UPDATE,
  ACCOUNT,
  CTA,
  USERS_ACCOUNT,
}

export default users
