import { gql } from 'apollo-boost'

const LIST = gql`
  query List(
    $geofences: [uuid!]!
    $approved: String!
    $rejected: String!
    $void: String!
    $enter: String!
    $exit: String!
    $dwell: String!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    event(where: { 
      geofence: { id: { _in: $geofences } } 
      geofence_action: { _in: [$enter,$exit,$dwell] }
      status: { _in: [$approved,$rejected,$void], _nin: ["PENDING"] }
      timestamp: { _gte: $startDate, _lte: $endDate }
    }) {
      id
      status
      timestamp
      geofence_id
      geofence_action
      points
      geofence {
        id
        name
      }
    }
  }
`

const events = {
  LIST,
}

export default events
