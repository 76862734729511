import React from 'react'
import styled from 'styled-components/macro'
import { Box } from 'rebass'

import Link from './Link'

import logo from '../assets/img/logo.svg'
import logoText from '../assets/img/logo-text.svg'

const Logo = styled(Box)`
  height: ${({ height }) => height || 45}px;
`

const Navigation = ({ small, ...others }) => (
  <Link to="/">
    <Logo as="img" {...others} src={small ? logo : logoText} alt="pointbee logo" />
  </Link>
)

export default Navigation
