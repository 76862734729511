import React from 'react'
import {
  Heading, Box, Flex, Text,
} from 'rebass'

const Section = ({
  heading,
  text,
  bigHeader,
  children,
}) => (
  <Flex
    as="section"
    mt={4}
    mb={3}
    flexDirection="column"
  >
    {heading && (
    <Box pb={2}>
      <Heading fontSize={bigHeader ? 5 : 4}>
        {heading}
      </Heading>
    </Box>
    )}
    {text && (
      <Text
        mb={bigHeader ? 4 : 3}
        color="textLight"
      >
        {text}
      </Text>
    )}
    {children}
  </Flex>
)

export default Section
