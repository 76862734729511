import React from 'react'

import Header from '../Header'

import routes from '../../constants/routes'

const Chevron = ({ text }) => (
  <>
    <svg
      width="8"
      height="12"
      viewBox="0 7 8 12"
    >
      <path d="M7.59 8.954l-3.265 3.118a.725.725 0 0
            0 0 1.058l3.265 3.118-2.047 1.954L.23 13.13a.725.725
            0 0 1 0-1.058L5.543 7C6.6 7.77 7.59 8.954 7.59 8.954z"
      />
    </svg>
    {` ${text}`}
  </>
)

const Home = ({
  admin,
  me,
  children,
  location: { pathname },
}) => {
  let links

  if (pathname.includes(routes.ACCOUNT)) {
    links = [
      { to: `${routes.ACCOUNT}/${routes.GEOFENCES}`, label: 'Zones' },
      { to: `${routes.ACCOUNT}/${routes.REWARDS}`, label: 'Rewards' },
      { to: `${routes.ACCOUNT}/${routes.REDEMPTIONS}`, label: 'Redemptions' },
      { to: `${routes.ACCOUNT}/${routes.EVENTS}`, label: 'Events' },
      { to: `${routes.ACCOUNT}/${routes.USERS}`, label: 'Users' },
      { to: `${routes.ACCOUNT}/${routes.SETTINGS}`, label: 'Account Settings' },
    ]
    if (!admin) delete links[4] // remove Users route
  } else if (pathname.includes(routes.GEOFENCES)) {
    links = [{
      to: `${routes.ACCOUNT}/${routes.GEOFENCES}`,
      label: <Chevron text="Zones" />,
      active: true,
    }]
  } else if (pathname.includes(routes.REWARDS)) {
    links = [{
      to: `${routes.ACCOUNT}/${routes.REWARDS}`,
      label: <Chevron text="Rewards" />,
      active: true,
    }]
  } else if (pathname.includes(routes.REDEMPTIONS)) {
    links = [{
      to: `${routes.ACCOUNT}/${routes.REDEMPTIONS}`,
      label: <Chevron text="Redemptions" />,
      active: true,
    }]
  } else if (pathname.includes(routes.EVENTS)) {
    links = [{
      to: `${routes.ACCOUNT}/${routes.EVENTS}`,
      label: <Chevron text="Events" />,
      active: true,
    }]
  }

  return (
    <>
      <Header links={links} me={me} />
      {children}
    </>
  )
}

export default Home
