import { gql } from 'apollo-boost'

const LIST = gql`
  query Geofence($accountId: uuid!) {
    geofence(where: { account: { id: { _eq: $accountId } } }) {
      id
      name
      geofence_status {
        status
      }
      account {
        id
        name
      }
    }
  }
`

const EDIT = gql`
  query Geofence($id: uuid!) {
    geofence(where: { id: { _eq: $id } }) {
      id
      radius
      name
      location
      geofence_status {
        status
      }
      points
      refresh_rate   
    }
  }
`

const geofences = {
  LIST,
  EDIT,
}

export default geofences
