import geofences from './geofences'
import users from './users'
import rewards from './rewards'
import redemptions from './redemptions'

const queries = {
  geofences,
  users,
  rewards,
  redemptions,
}

export default queries
