import { gql } from 'apollo-boost'

const LIST = gql`
  query Redemption($accountId: uuid!) {
    redemption(where: { reward: { account: { id: { _eq: $accountId } } } }) {
      id
      reward {
        id
        name
        account {
          id
          name
        }
      }
      points
      status    
    }
  }
`

const EDIT = gql`
  query Redemption($id: uuid!) {
    redemption(where: { id: { _eq: $id } }) {
      id
      reward {
        id
        name
        account {
          id
          name
        }
      }
      user {
        id
        name
        email
      }
      points
      status   
      reward_type 
      online_code
    }
  }
`

const redemptions = {
  LIST,
  EDIT,
}

export default redemptions
