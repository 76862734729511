import React from 'react'
import { Router } from '@reach/router'

import { SignIn, PasswordReset } from '../Auth'

import ROUTES from '../../constants/routes'

const PublicRoutes = () => (
  <Router>
    <SignIn path={ROUTES.SIGN_IN} default />
    <PasswordReset path={ROUTES.PASSWORD_RESET} />
  </Router>
)

export default PublicRoutes
