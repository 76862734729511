import { gql } from 'apollo-boost'

const UPDATE = gql`
  mutation UpdateRedemption(
    $id: uuid!
    $status: String!
    $onlineCode: String
  ) {
    update_redemption(
      where:{ id: { _eq: $id } }
      _set: {
        status: $status
        online_code: $onlineCode
      }
    ) {
      returning {
        id
      }
    }
  }
`

const rewards = {
  UPDATE,
}

export default rewards
