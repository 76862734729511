import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateReward(
    $name: String!
    $description: String!
    $points: Int!
    $status: String!
    $type: String!
    $account: uuid
  ) {
    insert_reward(
      objects: {
        name: $name
        description: $description
        points: $points
        status: $status
        type: $type
        account_id: $account
      }
    ) {
      returning {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateReward(
    $id: uuid!
    $name: String!
    $description: String!
    $points: Int!
    $status: String!
  ) {
    update_reward(
      where:{ id: { _eq: $id } }
      _set: {
        name: $name
        description: $description
        points: $points
        status: $status
      }
    ) {
      returning {
        id
      }
    }
  }
`

const DELETE = gql`
  mutation DeleteReward($id: uuid!) {
    delete_reward(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`

const rewards = {
  CREATE,
  UPDATE,
  DELETE,
}

export default rewards
