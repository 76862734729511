import React, { useState } from 'react'

import {
  Form,
  TextInput,
  FormSection,
} from '../Form'
import Card from '../Card'
import SocialButtons from './SocialButtons'

import { updateCredentialsSchema } from '../../utilities/validations'
import { auth } from '../../utilities/firebase'

const Profile = ({ admin, me }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    try {
      const { email } = updateCredentialsSchema.cast(values)
      await auth.currentUser.updateEmail(email)
      setSuccess('Your account was updated')
      setTimeout(() => auth.signOut(), 500)
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Card
      heading="Credentials"
      text="If you change your email address you will need to login again.
      Link your social media account to use the same email address in the mobile app."
    >
      <Form
        initialValues={{ email: me.email || '' }}
        validationSchema={updateCredentialsSchema}
        onSubmit={onSubmit}
        success={success}
        error={error}
        canSubmit={admin}
      >
        <FormSection>
          <TextInput name="email" placeholder="Email address" label="Email address" disabled={!admin} />
        </FormSection>
        <SocialButtons />
      </Form>
    </Card>
  )
}

export default Profile
