/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { useField } from 'formik'
import { Flex, Text } from 'rebass'
import { css } from 'styled-components/macro'

const inputStyle = css`
  color: ${({ theme }) => theme.colors.textNormal};
  background-color: #eff2f7;
  border: 1px solid #eff2f7;
  font-weight: 300;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .75rem 1rem;
  border-radius: 3px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.header};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`

const RadioButton = ({
  label, value, name, ...others
}) => {
  const [field, meta] = useField(name)
  return (
    <Flex
      flexDirection="column"
      mb={3}
    >
      <label htmlFor={value}>
        <Text
          {...field}
          {...others}
          id={value}
          value={value}
          as="input"
          type="radio"
          name={name}
          fontSize={2}
          mb={1}
          css={inputStyle}
          checked={field.value === value}
        />
        {` ${label}`}
      </label>
      {meta.touch && meta.error && (
        <Text color="error">
          {meta.error}
        </Text>
      )}
    </Flex>
  )
}

export default RadioButton
