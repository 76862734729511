import React from 'react'
import { css } from 'styled-components/macro'
import BarLoader from 'react-spinners/BarLoader'

import { colors } from '../constants/theme'

const style = css`
  height: 5rem;
  width: 100%;
`

const fullStyle = css`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`

const Loading = ({ full, ...others }) => (
  <div
    css={css`
        ${full ? fullStyle : style}
        display: flex;
        align-items: center;
        justify-content: center;
      `}
  >
    <BarLoader
      color={colors.primary}
      height={10}
      {...others}
    />
  </div>
)

export default Loading
