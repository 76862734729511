import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import styled from 'styled-components/macro'
import { navigate } from '@reach/router'
import { Flex, Text } from 'rebass'
import get from 'lodash.get'

const Wrapper = styled.div`
  position: relative;
  z-index: 1000;
`

const Name = styled(Flex).attrs({
  alignItems: 'center',
})`
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding: 10px;
  border-radius: 8px;

  :hover {
    background-color: #f5f7f9;
  }
`

const List = styled(Flex).attrs({
  flexDirection: 'column',
  as: 'ul',
})`
  margin: 0;
  list-style: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #f5f7f9;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0,0,0,.08);
  color: rgba(14,30,37,.87);
  left: 0;
  margin-top: 4px;
  min-width: 250px;
  position: absolute;
  padding: 0;
`

const Item = styled(Text).attrs({
  as: 'li',
})`
  padding: 15px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.textInactiveLink};
  }

  :first-child {
    border-radius: 8px 8px 0 0;
  }

  :last-child {
    border-radius: 0 0 8px 8px;
  }
`

const Navigation = ({ me }) => {
  const changeAccount = useStoreActions(actions => actions.account.change)
  const accountName = useStoreState(state => state.account.name)
  const [showPicker, setShowPicker] = useState(false)

  const show = () => !showPicker && setShowPicker(true)
  const hide = () => showPicker && setShowPicker(false)

  useEffect(() => {
    document.addEventListener('click', hide)
    return () => document.removeEventListener('click', hide)
  })

  return (
    <Wrapper>
      <Name onClick={() => {
        show()
      }}
      >
        {accountName}
        <svg
          viewBox="0 0 18 12"
          xmlns="http://www.w3.org/2000/svg"
          style={{ height: 8, paddingLeft: 10 }}
        >
          <path
            fill="#3C4855"
            d="M3.14.667l5.01 4.59c.47.43 1.23.43 1.7 0l5.01-4.59L18 3.544 9.85 11.01c-.47.43-1.23.43-1.7 0L0 3.545 3.14.667z"
          />
        </svg>
      </Name>
      {showPicker && (
      <List>
        {get(me, 'accounts', []).map(({ account: { id, name } }) => (
          <Item
            key={id}
            onClick={() => {
              changeAccount({ id, name })
              navigate('/')
            }}
          >
            {name}
          </Item>
        ))}
      </List>
      )}
    </Wrapper>
  )
}

export default Navigation
