import React from 'react'
import { css } from 'styled-components/macro'
import { Flex } from 'rebass'

const InputGroup = props => (
  <Flex
    css={css`
      & > * {
        margin-right: .5rem;
      }

      & > *:last-child {
        margin-right: 0;
      }
    `}
    flexDirection={['column', 'row']}
    {...props}
  />
)

export default InputGroup
