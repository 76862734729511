import React from 'react'
import { Link } from '@reach/router'
import { Flex, Text } from 'rebass'
import { css } from 'styled-components/macro'

import { colors } from '../../constants/theme'

export default ({ active, ...others }) => (
  <Flex
    as="li"
  >
    <Text
      as={Link}
      getProps={({ isCurrent }) => ({
        style: {
          color: isCurrent || active
            ? colors.primary
            : colors.textInactiveLink,
          fill: isCurrent || active
            ? colors.primary
            : colors.textInactiveLink,
        },
      })}
      css={css`
        text-decoration: none;
        font-weight: 600;
        transition: color .3s ease;
        padding: .75rem;

        &:hover {
          color: ${colors.primary} !important;
        }
      `}
      {...others}
    />
  </Flex>
)
