import React from 'react'
import { Router, Redirect } from '@reach/router'

import Home from '../Home'
// import GettingStarted from '../Home/GettingStarted' // TODO: uncomment when presentable

import Account from '../Account'
import Settings from '../Account/Settings'

import Geofences from '../Geofences'
import GeofenceList from '../Geofences/List'
import GeofencesCreate from '../Geofences/Create'
import GeofencesEdit from '../Geofences/Edit'

import Rewards from '../Rewards'
import RewardsList from '../Rewards/List'
import RewardsCreate from '../Rewards/Create'
import RewardsEdit from '../Rewards/Edit'

import Redemptions from '../Redemptions'
import RedemptionsList from '../Redemptions/List'
import RedemptionsEdit from '../Redemptions/Edit'

import Events from '../Events'
import EventsList from '../Events/List'

import NotFound from '../NotFound'

import routes from '../../constants/routes'

const AuthenticatedRoutes = ({ admin, me }) => (
  <Router primary={false /* stop accessibility scroll to new content */}>
    <Home path="/" me={me}>

      <NotFound default />
      {/* <GettingStarted path="/" /> // TODO: make this component presentable then delete below Redirect */}
      <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />

      <Account path={routes.ACCOUNT}>
        <NotFound default />
        <GeofenceList path={routes.GEOFENCES} admin={admin} />
        <RewardsList path={routes.REWARDS} admin={admin} />
        <RedemptionsList path={routes.REDEMPTIONS} />
        <EventsList path={routes.EVENTS} admin={admin} />
        <Settings path={routes.SETTINGS} admin={admin} me={me} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />
      </Account>

      <Geofences path={routes.GEOFENCES}>
        <GeofencesCreate path={routes.NEW} admin={admin} />
        <GeofencesEdit path={routes.ID} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />
      </Geofences>

      <Rewards path={routes.REWARDS}>
        <RewardsCreate path={routes.NEW} admin={admin} />
        <RewardsEdit path={routes.ID} admin={admin} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.REWARDS}`} noThrow />
      </Rewards>

      <Redemptions path={routes.REDEMPTIONS}>
        <RedemptionsEdit path={routes.ID} admin={admin} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.REDEMPTIONS}`} noThrow />
      </Redemptions>

      <Events path={routes.EVENTS}>
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.EVENTS}`} noThrow />
      </Events>

    </Home>
  </Router>
)

export default AuthenticatedRoutes
