import React from 'react'
import { Flex } from 'rebass'
import { css } from 'styled-components/macro'

import Container from '../Container'
import Link from './Link'

const Links = ({ links = [] }) => (
  <Container
    as="nav"
  >
    <Flex
      flexWrap="wrap"
      as="ul"
      css={css`
        list-style: none;
        padding: 0;  
        margin: .75rem -12px;
      `}
    >
      {links.map(l => (
        <Link
          key={l.to}
          to={l.to}
          {...l}
        >
          {l.label}
        </Link>
      ))}
    </Flex>
  </Container>
)

export default Links
