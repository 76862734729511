import React from 'react'
import { css } from 'styled-components/macro'

import PasswordResetForm from './PasswordResetForm'
import Container from '../Container'

const PasswordReset = () => (
  <Container css={css`max-width: 500px;`}>
    <h1 style={{ textAlign: 'center' }}>Password Reset</h1>
    <PasswordResetForm />
  </Container>
)

export default PasswordReset
