import { gql } from 'apollo-boost'

const LIST = gql`
  query Reward($accountId: uuid!) {
    reward(where: { account: { id: { _eq: $accountId } } }) {
      id
      name
      status
      account {
        id
        name
      }
    }
  }
`

const EDIT = gql`
  query Reward($id: uuid!) {
    reward(where: { id: { _eq: $id } }) {
      id
      name
      description
      points
      status
      type
    }
  }
`

const rewards = {
  LIST,
  EDIT,
}

export default rewards
