import React from 'react'
import { css } from 'styled-components/macro'
import {
  Box, Heading, Text, Flex,
} from 'rebass'

import Link from '../Link'
import Container from '../Container'
import Logo from '../Logo'
import SignInForm from './SignInForm'
import PasswordResetLink from './PasswordResetLink'
import SignInSocial from './SignInSocial'

import { colors } from '../../constants/theme'

const SignIn = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    as={Container}
    css={css`
      max-width: 700px;
      margin: 0 auto;
    `}
  >
    <Logo
      m={4}
      height={75}
    />
    <Heading mb={3}>Log in to your account</Heading>
    <Text mb={4}>
      {'Don\'t have an account yet? '}
      <Link
        target="_blank"
        color="primary"
        href="https://www.dotdotdata.com/contact/"
      >
        <span css={css`color: ${colors.primary};`}>Request access</span>
      </Link>
    </Text>
    <Flex
      flexDirection="column"
      alignItems="center"
      css={css`min-width: 350px;`}
    >
      <SignInForm />
      <PasswordResetLink />
    </Flex>
    <Box
      as="hr"
      css={css`
        width: 50%;
        margin: 45px 0;
        border-top: 1px solid #e6e6e6;
      `}
    />
    <SignInSocial />
  </Flex>
)

export default SignIn
