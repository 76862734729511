/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { useField } from 'formik'
import { Flex, Text } from 'rebass'

const TextInput = ({ name, label, ...others }) => {
  const [field, meta] = useField(name)
  return (
    <Flex
      flexDirection="column"
      mb={3}
      {...others}
    >
      <label htmlFor={name}>
        <Text
          {...field}
          pl={2}
          id={name}
          as="input"
          type="checkbox"
          name={name}
          fontSize={2}
          mb={1}
          mr={2}
          checked={field.value}
        />
        {label}
      </label>
      {meta.touch && meta.error && (
        <Text color="error">
          {meta.error}
        </Text>
      )}
    </Flex>
  )
}

export default TextInput
