import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateGeofence(
    $name: String!
    $radius: numeric!
    $location: geography!
    $status: String!
    $points: Int!
    $refreshRate: Int!
    $account: uuid!
  ) {
    insert_geofence(
      objects: {
        name: $name
        radius: $radius
        location: $location
        status: $status
        points: $points
        refresh_rate: $refreshRate
        account_id: $account
      }
    ) {
      returning {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateGeofence(
    $id: uuid!
    $name: String!
    $radius: numeric!
    $location: geography!
    $status: String!
    $points: Int!
    $refreshRate: Int!
  ) {
    update_geofence(
      where:{ id: { _eq: $id } }
      _set: {
        name: $name
        radius: $radius
        location: $location
        status: $status
        points: $points
        refresh_rate: $refreshRate
      }
    ) {
      returning {
        id
      }
    }
  }
`

const DELETE = gql`
  mutation DeleteGeofence($id: uuid!) {
    delete_geofence(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`

const geofences = {
  CREATE,
  UPDATE,
  DELETE,
}

export default geofences
