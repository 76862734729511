import React, { useState } from 'react'
import styled from 'styled-components/macro'

import firebase, { auth } from '../../utilities/firebase'
import facebookLogo from '../../assets/img/facebook-logo.png'
import googleLogo from '../../assets/img/google-logo.svg'

import { colors, breakpoints } from '../../constants/theme'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${breakpoints[0]}) {
    align-items: center;
    flex-direction: row;  
  }
`

const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  color: white;
  background-color: ${props => (props.google ? '#4285F4' : '#395795')};
  padding: ${props => (props.google ? 0 : 10)}px;
  padding-right: 10px;
  box-shadow: 0px 1px 1px 0px rgba(209,209,209,1);
  min-width: 230px;

  img {
    margin-right: 10px;
    height: ${props => (props.google ? 39 : 20)}px;
  }

  @media (min-width: ${breakpoints[0]}) {
    align-items: center;
    flex-direction: row;
    margin: 0 20px 0 0;
  }
`

const Error = styled.p`
  color: ${colors.error};
`

const SignInSocial = () => {
  const [errorMessage, setErrorMessage] = useState('')

  const signInWithRedirect = async (provider) => {
    setErrorMessage('')

    try {
      await auth.signInWithRedirect(provider === 'google'
        ? new firebase.auth.GoogleAuthProvider()
        : new firebase.auth.FacebookAuthProvider())
    } catch (error) {
      setErrorMessage(error.toString())
    }
  }

  return (
    <>
      <Wrapper>
        <Button
          type="button"
          onClick={() => signInWithRedirect('google')}
          google
        >
          <img src={googleLogo} alt="link google" />
          Continue with Google
        </Button>
        <Button
          type="button"
          onClick={() => signInWithRedirect('facebook')}
        >
          <img src={facebookLogo} alt="link facebook" />
          Continue with Facebook
        </Button>
      </Wrapper>
      <Error>{errorMessage}</Error>
    </>
  )
}

export default SignInSocial
