import geofences from './geofences'
import rewards from './rewards'
import redemptions from './redemptions'
import events from './events'
import users from './users'

const queries = {
  geofences,
  rewards,
  redemptions,
  events,
  users,
}

export default queries
