import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  Toggle,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Section from '../Section'
import Loading from '../Loading'
import NotFound from '../NotFound'

import { updateRedemptionSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Edit = ({ id, admin }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.redemptions.EDIT, {
    variables: { id },
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const [updateRedemption] = useMutation(mutations.redemptions.UPDATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      id,
      onlineCode: values.onlineCode || null,
      status: values.status ? 'COMPLETED' : 'APPROVED',
    }

    try {
      await updateRedemption({ variables })
      setSuccess('Redemption was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.redemption) return <NotFound noMargin />

  const rewardType = get(data, 'redemption[0].reward_type', '')
  const status = get(data, 'redemption[0].status', '')
  let disabledStatus = true
  if (status !== 'REJECTED' && status !== 'PENDING') {
    if (admin) {
      disabledStatus = false
    } else if (status === 'APPROVED') {
      // clients can only update when in APPROVED status, so they cannot
      // go back once COMPLETED
      disabledStatus = false
    }
  }

  return (
    <>
      <Section
        heading="Update Redemption"
        text="Redemption process completion and details."
      >
        <Card>
          {loading
            ? <Loading />
            : (
              <Form
                initialValues={{
                  rewardName: get(data, 'redemption[0].reward.name', ''),
                  userName: get(data, 'redemption[0].user.name', ''),
                  userEmail: get(data, 'redemption[0].user.email', ''),
                  points: get(data, 'redemption[0].points', ''),
                  onlineCode: get(data, 'redemption[0].online_code', ''),
                  status: get(data, 'redemption[0].status', '') === 'COMPLETED',
                  rewardType,
                }}
                validationSchema={updateRedemptionSchema}
                onSubmit={onSubmit}
                submitLabel="Update Redemption"
                error={error}
                success={success}
              >
                <FormSection
                  heading="Status"
                  text={
                    rewardType === 'IN_PERSON'
                      ? 'In person rewards automatically update from the mobile app.'
                      : 'Once the reward is dispersed you should mark it as complete.'
                  }
                >
                  <Toggle
                    name="status"
                    label={rewardType === 'ONLINE'
                      ? ['Complete, instructions have been provided', 'Instructions not yet provided']
                      : ['Complete, reward has been dispersed', 'Reward has not been dispersed']}
                    disabled={disabledStatus}
                    help={status !== 'COMPLETED' && disabledStatus && 'Cannot update, check Current Status'}
                  />
                </FormSection>

                {rewardType === 'ONLINE' ? (
                  <FormSection
                    heading="Instructions"
                    text="Provide instructions to the user for online rewards. For instance, provide the e-gift card number and pin."
                  >
                    <TextInput name="onlineCode" label="Online Instructions" area />
                  </FormSection>
                ) : null}

                <FormSection
                  heading="Details"
                  text="Information about the reward and user. This cannot be updated."
                >
                  <TextInput label="Current Status" disabled value={status} />
                  <TextInput name="rewardName" label="Reward Name" disabled />
                  <TextInput name="rewardType" label="Reward Type" disabled />
                  <TextInput name="userName" label="User Name" disabled />
                  {admin && <TextInput name="userEmail" label="User Email" disabled />}
                </FormSection>
              </Form>
            )
        }
        </Card>
      </Section>
    </>
  )
}

export default Edit
