/* eslint-disable no-param-reassign */
import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'
import { useAuthState } from 'react-firebase-hooks/auth'
import { StoreProvider, createStore, action } from 'easy-peasy'

import Theme from './Theme'
import GetRole from './GetRole'
import Public from './Public'
import EnvBanner from './EnvBanner'
import Loading from '../Loading'

import { auth } from '../../utilities/firebase'
import createApolloClient from '../../utilities/createApolloClient'

const store = createStore({
  account: {
    id: null,
    name: null,
    change: action((state, payload) => {
      state.id = payload.id
      state.name = payload.name
    }),
  },
})

const App = () => {
  const [user, loading] = useAuthState(auth)

  if (loading) return <Loading full />

  return (
    <StoreProvider store={store}>
      <Theme>
        <>
          <EnvBanner />
          {user && user.uid
            ? (
              <ApolloProvider client={createApolloClient()}>
                <GetRole user={user} />
              </ApolloProvider>
            ) : <Public />
          }
        </>
      </Theme>
    </StoreProvider>
  )
}

export default App
