import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components/macro'
import { Normalize } from 'styled-normalize'
import 'react-dates/initialize' // initialize for all uses
import 'react-dates/lib/css/_datepicker.css'

import theme, { colors } from '../../constants/theme'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

  html {
    background-color: ${colors.background};
  }

  body {
    font-family: 'Open Sans', sans-serif;
    color: ${colors.textNormal};
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Normalize />
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
)

export default Theme
