import React, { useState } from 'react'

import {
  Form,
  TextInput,
  InputGroup,
  FormSection,
} from '../Form'
import Card from '../Card'

import { updateGeneralSchema } from '../../utilities/validations'
import { auth } from '../../utilities/firebase'

const Profile = ({ admin, me }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    try {
      const { name } = updateGeneralSchema.cast(values)
      await auth.currentUser.updateProfile({ displayName: name })
      setSuccess('Your account was updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Card
      heading="General information"
    >
      <Form
        initialValues={{ name: me.name || '' }}
        validationSchema={updateGeneralSchema}
        onSubmit={onSubmit}
        success={success}
        error={error}
        canSubmit={admin}
      >
        <FormSection>
          <InputGroup>
            <TextInput name="name" placeholder="Full name" label="Full name" disabled={!admin} />
          </InputGroup>
        </FormSection>
      </Form>
    </Card>
  )
}

export default Profile
