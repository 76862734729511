import React from 'react'
import { useField } from 'formik'
import { Flex, Text } from 'rebass'
import { css } from 'styled-components/macro'

const inputStyle = css`
  color: #3F4B5B;
  background-color: #eff2f7;
  border: 1px solid #eff2f7;
  font-weight: 300;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: .75rem 1rem;
  border-radius: 3px;

  &:focus {
    background-color: ${({ theme }) => theme.colors.header};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: #AFB8C6; 
  }
`

const Component = ({
  label,
  area,
  display,
  field = {},
  meta = {},
  ...others
}) => (
  <Flex
    flexDirection="column"
    mb={3}
    width="100%"
  >
    {label && (
      <Text
        as="label"
        mb={2}
        fontSize={1}
        fontWeight="bold"
        color="3B4858"
        css={css`text-transform: uppercase;`}
      >
        {label}
      </Text>
    )}
    <Text
      {...field}
      {...others}
      as={area ? 'textarea' : 'input'}
      fontSize={2}
      mb={0}
      css={inputStyle}
    />
    {meta.touch && meta.error && (
      <Text
        mt={1}
        color="error"
      >
        {meta.error}
      </Text>
    )}
  </Flex>
)

const WithFormik = ({ name, ...others }) => {
  const [field, meta] = useField(name)
  return (
    <Component
      name={name}
      field={field}
      meta={meta}
      {...others}
    />
  )
}

const WithoutFormik = props => <Component {...props} />

// We need separate components because we cannot place `useField`
// within a condition, so place entire components within
// the condition instead
const TextInput = ({ name, ...others }) => (
  name
    ? <WithFormik name={name} {...others} />
    : <WithoutFormik name={name} {...others} />
)

export default TextInput
