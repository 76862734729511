import React from 'react'
import { Button } from 'rebass'
import { css } from 'styled-components/macro'
import { darken, lighten } from 'polished'

const fillStyle = css`
  width: 100%;
  height: 100%;
  max-width: none;
  flex: 1 1 auto;
`

export default ({
  color,
  fill,
  outline,
  caps,
  tall,
  error,
  ...others
}) => (
  <Button
    py={tall && 3}
    {...others}
    css={css`${({ theme, disabled }) => {
      const mainColor = theme.colors[color] || color || theme.colors.secondary
      return css`
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        text-align: center;
        ${fill && fillStyle}
        border-width: 1px;
        text-transform: ${caps && 'uppercase'};
        color: ${outline ? mainColor : 'white'};
        border: solid 1px ${outline ? mainColor : 'transparent'};
        border-color: ${outline ? mainColor : 'transparent'};
        background-color: ${outline ? 'white' : lighten(disabled ? 0.2 : 0, mainColor)};
        transition: all .15s ease;
        font-weight: normal;
        outline: none;
    
        &:hover {
          background-color: ${!disabled && !outline && darken(0.08, mainColor)};
          box-shadow: ${!disabled && '0 2px 2px 0 rgba(0, 0, 0, .1)'};
          transform: translateY(${disabled ? 0 : -1}px);
        };   
      `
    }}`}
  />
)
