import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import { Form, TextInput } from '../Form'
import Card from '../Card'
import Loading from '../Loading'

import { updateAccountSchemaNoLimit } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Account = ({ admin }) => {
  const accountId = useStoreState(state => state.account.id)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.BUSINESS, {
    suspend: false,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
    },
  })
  const [updateAccount] = useMutation(mutations.users.ACCOUNT)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    const variables = {
      ...updateAccountSchemaNoLimit.cast(values),
      id: accountId,
    }

    try {
      await updateAccount({ variables })
      setSuccess('Your account was updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>

  return (
    <Card
      heading="Business"
      text="This information is publicly displayed in our mobile application."
    >
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              name: get(data, 'account[0].name', ''),
              ctaImage: get(data, 'account[0].cta_image', '') || '',
              description: get(data, 'account[0].description', '') || '',
            }}
            validationSchema={updateAccountSchemaNoLimit}
            onSubmit={onSubmit}
            success={success}
            error={error}
            canSubmit={admin}
          >
            <TextInput name="name" placeholder="Business Name" label="Name" disabled={!admin} />
            <TextInput name="ctaImage" placeholder="Enter logo URL" label="Logo URL" disabled={!admin} />
            <TextInput name="description" placeholder="Business Description" label="Description" area disabled={!admin} />
          </Form>
        )
      }
    </Card>
  )
}

export default Account
