import React, { useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { css } from 'styled-components/macro'
import moment from 'moment'
import get from 'lodash.get'

import {
  Form,
  GeofenceSelect,
  DateSelect,
  CheckBox,
  Label,
} from '../Form'
import Card from '../Card'
import Loading from '../Loading'
import SimpleLineChart from './SimpleLineChart'

import { listEventsSchema } from '../../utilities/validations'
import queries from '../../queries'

const EventsList = ({ admin }) => {
  const [variables, setVariables] = useState({
    geofences: ['00adcac5-5ef5-48d9-9141-8f3170c09557'], // not real
    approved: 'DUMMY',
    rejected: 'DUMMY',
    void: 'DUMMY',
    enter: 'DUMMY',
    exit: 'DUMMY',
    dwell: 'DUMMY',
    startDate: new Date(),
    endDate: new Date(),
  })

  const { data, error, loading } = useQuery(queries.events.LIST, {
    suspend: false,
    fetchPolicy: 'network-only',
    variables,
  })

  const onSubmit = async (values) => {
    if (values.geofences && values.startDate && values.endDate) {
      setVariables({
        geofences: values.geofences.map(g => g.value),
        approved: values.approved ? 'APPROVED' : 'DUMMY',
        rejected: values.rejected ? 'REJECTED' : 'DUMMY',
        void: values.void ? 'VOID' : 'DUMMY',
        enter: values.enter ? 'ENTER' : 'DUMMY',
        exit: values.exit ? 'EXIT' : 'DUMMY',
        dwell: values.dwell ? 'DWELL' : 'DUMMY',
        startDate: moment(values.startDate).utc().toDate(),
        endDate: moment(values.endDate).utc().add(1, 'day').toDate(),
      })
    }
  }

  return (
    <Card>
      <Form
        initialValues={{
          geofences: [],
          startDate: null,
          endDate: null,
          approved: true,
          enter: true,
          exit: true,
          dwell: true,
          rejected: true,
          void: true,
        }}
        onSubmit={onSubmit}
        submitLabel="Search"
        error={error}
        validationSchema={listEventsSchema}
      >
        <GeofenceSelect
          admin={admin}
          name="geofences"
          label="Zone"
          placeholder="Select a zone"
        />
        <DateSelect
          label="Dates (60 day history)"
          isOutsideRange={date => moment().isSameOrBefore(date) || moment().diff(date, 'day') > 60}
        />
        <Label label="Status" row>
          <CheckBox mr={3} name="approved" label="Approved" />
          <CheckBox mr={3} name="rejected" label="Rejected" />
          <CheckBox name="void" label="Void" />
        </Label>
        <Label label="Action" row>
          <CheckBox mr={3} name="enter" label="Enter" />
          <CheckBox mr={3} name="exit" label="Exit" />
          <CheckBox mr={3} name="dwell" label="Dwell" />
        </Label>
      </Form>
      {loading && <Loading />}
      {error && error.message}
      {!loading && !error && (
        <div css={css`
          padding: 50px 50px 20px 0;
        `}
        >
          <SimpleLineChart events={get(data, 'event')} />
        </div>
      )}
    </Card>
  )
}

export default EventsList
