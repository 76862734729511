import React from 'react'
import { Box } from 'rebass'
import { css } from 'styled-components/macro'

const Status = ({ active, small, ...others }) => (
  <Box
    active={active}
    alignSelf="center"
    css={css`
      background-color: ${({ theme }) => (active ? theme.colors.accent : '#e0e6ed')};
      border-radius: 50%;
      color: #fff;
      text-align: center;
      width: ${small ? 20 : 35}px;
      height: ${small ? 20 : 35}px;
      line-height: ${small ? 18 : 35}px;
    
      svg {
        width: ${small ? 10 : 15}px;
        margin-top: ${small ? 0 : 10}px;
        fill: #fff;
      }
    `}
    {...others}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path stroke="white" d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
    </svg>
  </Box>
)

export default Status
