import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useMutation } from 'react-apollo-hooks'
import { navigate } from '@reach/router'

import {
  TextInput,
  Toggle,
  RadioButton,
  FormSection,
  Form,
  AccountSelect,
} from '../Form'
import Card from '../Card'
import Section from '../Section'

import { createRewardSchema } from '../../utilities/validations'
import mutations from '../../mutations'
import routes from '../../constants/routes'

const initialValues = {
  name: '',
  description: '',
  points: '',
  status: false,
  account: '',
  type: 'IN_PERSON',
}

const Create = ({ admin }) => {
  const accountId = useStoreState(state => state.account.id)
  const [error, setError] = useState(null)
  const [createReward] = useMutation(mutations.rewards.CREATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const variables = {
      ...createRewardSchema.cast(values),
      status: values.status ? 'ACTIVE' : 'INACTIVE',
      account: values.account || accountId,
    }

    try {
      await createReward({ variables })
      navigate(routes.REWARDS_ROOT)
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Section
      heading="Create a New Reward"
      text="Give your customers a reason to keep coming back."
      bigHeader
    >
      <Card>
        <Form
          initialValues={initialValues}
          validationSchema={createRewardSchema}
          onSubmit={onSubmit}
          submitLabel="Create Reward"
          error={error}
        >
          <FormSection>
            <Toggle name="status" label={['Activate right away', 'Inactive, I will activate later']} />
          </FormSection>

          <FormSection
            heading="Type"
            text="Whether reward must be redeemed in person, can be claimed at a later date or process online.
            Claimable on Online rewards require logging into the portal to complete the redemption process. Please note, this value cannot be changed."
          >
            <RadioButton label="In Person" name="type" value="IN_PERSON" defaultChecked />
            <RadioButton label="Claimable" name="type" value="CLAIMABLE" />
            <RadioButton label="Online" name="type" value="ONLINE" />
          </FormSection>

          <FormSection
            heading="Public"
            text="This information will appear in the mobile application."
          >
            <TextInput name="name" placeholder="Catchy and descriptive name" label="Reward Name" />
            <TextInput name="description" placeholder="Describe this reward in detail" label="Reward Description" area />
          </FormSection>

          <FormSection
            heading="Points"
            text="Points are the currency used to redeem this reward. When
              determining the cost of this reward keep in mind how many points your customers receive by crossing
              into your zones."
          >
            <TextInput name="points" placeholder="Points required to redeem this reward" type="number" label="Point Value" />
          </FormSection>

          {admin && (
            <FormSection
              heading="Admin"
              text="By default the person creating a reward will be its owner, that's you! If creating for a client please specify here."
            >
              <AccountSelect name="account" placeholder="Select the owner of this reward" label="Reward owner" />
            </FormSection>
          )}
        </Form>
      </Card>
    </Section>
  )
}

export default Create
