import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useQuery } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import Loading from '../Loading'
import Header from '../Header'
import Authenticated from './Authenticated'

import queries from '../../queries'

const GetRole = ({ user }) => {
  const changeAccount = useStoreActions(actions => actions.account.change)
  const { data, loading, error } = useQuery(queries.users.ME, { variables: { authId: user.uid }, suspend: false })
  const [role, setRole] = useState()

  useEffect(() => {
    user.getIdTokenResult(true).then((result) => {
      setRole(get(result, 'claims["https://hasura.io/jwt/claims"]["x-hasura-default-role"]', 'MOBILE'))
      const account = get(data, 'user[0].accounts[0].account', {})
      changeAccount(account)
    })
  }, [data, changeAccount, user, loading, error])

  if (loading || !role) return <Loading full />

  // Show header so user can use the logout button, we want them to logout if for some reason
  // their claims are messed up and we reset them in the admin console, logging out then
  // back in will update the claims on the client, this is only a fail-safe

  if (role !== 'admin' && role !== 'client') {
    return (
      <>
        <Header />
        Your account is not setup as a Pointbee Partner. If you would like to market your business
        via the Pointbee Platform please
        {' '}
        <a href="http://www.dotdotdata.com/contact">contact us</a>
        .
      </>
    )
  }

  if (error) {
    return (
      <>
        <Header />
        <Text color="error">{error.message}</Text>
      </>
    )
  }

  const me = get(data, 'user[0]', {})
  me.name = user.displayName
  me.email = user.email
  me.role = role

  return (
    <Authenticated admin={me.role === 'ADMIN'} me={me} />
  )
}

export default GetRole
