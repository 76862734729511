import React, { useState } from 'react'

import { Form, TextInput } from '../Form'

import { signInSchema } from '../../utilities/validations'
import { auth } from '../../utilities/firebase'

const SignInForm = () => {
  const [error, setError] = useState(null)

  const onSubmit = async ({ email, password }, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)

    try {
      await auth.signInWithEmailAndPassword(email, password)
    } catch (e) {
      setError('Invalid username or password')
    }

    setSubmitting(false)
  }

  return (
    <Form
      initialValues={{ email: '', password: '' }}
      validationSchema={signInSchema}
      onSubmit={onSubmit}
      submitLabel="Log In"
      error={error}
    >
      <TextInput
        name="email"
        type="text"
        placeholder="Email address"
        autoComplete="email-address"
      />
      <TextInput
        name="password"
        type="password"
        placeholder="Password"
        autoComplete="password"
      />
    </Form>
  )
}

export default SignInForm
