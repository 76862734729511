import React from 'react'
import { css } from 'styled-components/macro'
import {
  Card,
  Heading,
  Text,
  Box,
} from 'rebass'

export default ({
  heading,
  text,
  children,
  ...others
}) => (
  <Card
    p={3}
    mb={4}
    borderRadius={3}
    border="1px solid #EEEEEE"
    boxShadow="0 2px 4px 0 rgba(14,30,37,.12)"
    bg="white"
    {...others}
  >
    {heading && (
      <Box
        pb={2}
        mb={4}
        css={css`border-bottom: solid 1px #EEEEEE;`}
      >
        <Heading
          as="h3"
          fontSize={3}
        >
          {heading}
        </Heading>
      </Box>
    )}
    {text && (
    <Text
      mb={4}
      color="textLight"
    >
      {text}
    </Text>
    )}
    {children}
  </Card>
)
