import React from 'react'

import Container from '../Container'

const Account = ({ children }) => (
  <>
    <Container as="main">
      {children}
    </Container>
  </>
)

export default Account
