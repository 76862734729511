import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useQuery } from 'react-apollo-hooks'
import { Flex, Text, Heading } from 'rebass'
import { css } from 'styled-components/macro'

import Button from '../Button'
import Card from '../Card'
import Link from '../Link'
import Status from '../Status'
import Loading from '../Loading'

import queries from '../../queries'
import routes from '../../constants/routes'

const RedemptionsList = () => {
  const accountId = useStoreState(state => state.account.id)
  const [completed, setCompleted] = useState(false)

  const { data, error, loading } = useQuery(queries.redemptions.LIST, {
    suspend: false,
    fetchPolicy: 'network-only',
    variables: { accountId },
  })
  if (loading) return <Loading />
  return (
    <>
      {error && error.message}
      {!error && (
        <Card p={0}>
          <Flex
            p={3}
          >
            <Button
              fill
              onClick={() => setCompleted(!completed)}
            >
              {`${completed ? 'Hide' : 'Show'} Completed Redemptions`}
            </Button>
          </Flex>
          <Flex
            as="ul"
            p={0}
            m={0}
            flexDirection="column"
          >
            {data.redemption.filter(r => (completed ? true : r.status !== 'COMPLETED')).map(r => (
              <Flex
                key={r.id}
                as="li"
                width="100%"
                css={css`
                  background-color: white;

                  &:hover {
                    background-color: ${({ theme }) => theme.colors.header};
                  }
                `}
              >
                <Link
                  width="100%"
                  p={3}
                  to={`${routes.REDEMPTIONS_ROOT}/${r.id}`}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex flexDirection="column">
                      <Heading as="h3">{r.reward.name}</Heading>
                      <Text color="textLight" mt={2}>{`Owned by ${r.reward.account.name}`}</Text>
                      <Text color="textLight" mt={2}>{`${r.id}`}</Text>
                    </Flex>
                    <Flex flexDirection="column">
                      <Status active={r.status === 'COMPLETED'} />
                      <Text mt={2}>{r.status === 'COMPLETED' ? 'Completed' : ''}</Text>
                      <Text mt={2}>{r.status === 'APPROVED' ? 'Approved' : ''}</Text>
                      <Text mt={2}>{r.status === 'REJECTED' ? 'Rejected' : ''}</Text>
                      <Text mt={2}>{r.status === 'PENDING' ? 'Pending' : ''}</Text>
                    </Flex>
                  </Flex>
                </Link>
              </Flex>
            ))}
          </Flex>
        </Card>
      )}
    </>
  )
}

export default RedemptionsList
