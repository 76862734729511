import React from 'react'
import { Flex } from 'rebass'
import { css } from 'styled-components/macro'

import Link from '../Link'

import ROUTES from '../../constants/routes'

const PasswordResetLink = () => (
  <Flex
    as={Link}
    mt={3}
    color="primary"
    to={ROUTES.PASSWORD_RESET}
    css={css`align-self: flex-end;`}
  >
    Forgot your password?
  </Flex>
)

export default PasswordResetLink
