const breakpoints = ['40em', '52em', '64em']

const colors = {
  primary: '#8181DD',
  secondary: '#44AFE6',
  accent: '#12CE66',
  header: '#F9FAFC',
  background: '#F9FAFC',
  warning: '#EFC210',
  error: '#BB1C1C',
  success: '#1AB25E',
  textInactiveLink: '#B0B9C5',
  textLight: '#8592A6',
  textNormal: '#3C4858',
}

const theme = {
  breakpoints,
  colors,
}

export {
  theme as default,
  colors,
  breakpoints,
}
