import React from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import { Flex } from 'rebass'
import { css } from 'styled-components/macro'

import Button from '../Button'
import Container from '../Container'
import Logo from '../Logo'
import AccountPicker from './AccountPicker'

import { auth } from '../../utilities/firebase'

const AuthenticatedNav = ({ links, me }) => {
  const client = useApolloClient()

  const signOut = async () => {
    try {
      await auth.signOut()
      // Apollo caches all query results, get rid of them when the login state changes
      client.clearStore()
    } catch (e) {
      // do nothing
    }
  }

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      py={2}
      css={css`background-color: ${props => props.theme.colors.header};`}
    >
      <Flex
        as={Container}
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Logo small />
          <AccountPicker me={me} />
        </Flex>
        <Flex alignItems="center">
          {links}
          <Button onClick={signOut}>Sign Out</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AuthenticatedNav
