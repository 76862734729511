import React, { useState } from 'react'

import { Form, TextInput } from '../Form'

import { passwordResetSchema } from '../../utilities/validations'
import { auth } from '../../utilities/firebase'

const PasswordResetForm = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const onSubmit = async ({ email }, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    try {
      await auth.sendPasswordResetEmail(email)
      setSuccess('We just sent you an email to reset your password')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Form
      initialValues={{ email: '' }}
      validationSchema={passwordResetSchema}
      onSubmit={onSubmit}
      submitLabel="Reset"
      error={error}
      success={success}
    >
      <TextInput
        name="email"
        type="text"
        placeholder="Email address"
        autoComplete="email-address"
      />
    </Form>
  )
}

export default PasswordResetForm
