import React, { useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { Flex, Text } from 'rebass'
import { useField, useFormikContext } from 'formik'
import { css } from 'styled-components/macro'
import moment from 'moment'

import Button from '../Button'

const wrapperStyle = css`
  .DateInput, .DateInput_input {
    background-color: transparent;
    font-size: 1rem;
  }

  .DateInput_input__focused {
    border-bottom: 1px solid #8181DD;
  }

  .DateInput_input::placeholder {
    color: #AFB8C6;
    font-weight: 300;
  }

  .DateRangePickerInput {
    background-color: #eff2f7;
    border-radius: 3px;
  }

  .CalendarDay__selected {
    background: #8181DD;
    border: 1px double #8181DD;

    &:hover {
      background: #8181DD;
      border: 1px double #8181DD;
    }
  }

  .CalendarDay__selected_span {
    background: #c1c1e4;
    border: 1px double #c1c1e4;
    
    &:hover {
      background: #8181DD;
      border: 1px double #8181DD;
    }
  }

  .CalendarDay__hovered_span {
    background: #c1c1e4;
    border: 1px double #c1c1e4;
    color: white;

    &:hover {
      background: #c1c1e4;
      border: 1px double #c1c1e4;
      color: white;
    }
  }
`

const DateSelect = ({ label, ...others }) => {
  const [focusedInput, setfocusedInput] = useState()
  const [startDateField, startDateMeta] = useField('startDate')
  const [endDateField, endDateMeta] = useField('endDate')
  const { setFieldValue, setFieldTouched } = useFormikContext()

  const Controls = () => (
    <Flex pb={3} pl={3}>
      <Button
        type="button"
        mr={2}
        onClick={() => {
          setFieldValue('startDate', moment())
          setFieldValue('endDate', moment())
        }}
      >
      Today
      </Button>
      <Button
        type="button"
        onClick={() => {
          setFieldValue('startDate', moment().subtract(1, 'day'))
          setFieldValue('endDate', moment().subtract(1, 'day'))
        }}
      >
      Yesterday
      </Button>
    </Flex>
  )

  return (
    <Flex
      flexDirection="column"
      mb={3}
      css={wrapperStyle}
    >
      {label && (
        <Text
          as="label"
          mb={2}
          fontSize={1}
          fontWeight="bold"
          color="3B4858"
          css={css`text-transform: uppercase;`}
        >
          {label}
        </Text>
      )}
      <DateRangePicker
        noBorder
        startDate={startDateField.value}
        startDateId="startDate"
        endDate={endDateField.value}
        endDateId="endDate"
        onDatesChange={({ startDate: s, endDate: e }) => {
          setFieldValue('startDate', s)
          setFieldValue('endDate', e)
          setFieldTouched('startDate', true)
          setFieldTouched('endDate', true)
        }}
        focusedInput={focusedInput}
        onFocusChange={f => setfocusedInput(f)}
        renderCalendarInfo={Controls}
        minimumNights={0}
        numberOfMonths={1}
        {...others}
      />
      {startDateMeta.touch && startDateMeta.error && (
        <Text color="error" mt={2}>
          {startDateMeta.error}
        </Text>
      )}
      {endDateMeta.touch && endDateMeta.error && (
        <Text color="error" mt={2}>
          {endDateMeta.error}
        </Text>
      )}
    </Flex>
  )
}

export default DateSelect
