import React from 'react'
import { Flex, Text } from 'rebass'
import { css } from 'styled-components/macro'


const EventsList = ({ label, row, children }) => (
  <>
    <Text
      as="label"
      mb={2}
      fontSize={1}
      fontWeight="bold"
      color="3B4858"
      css={css`text-transform: uppercase;`}
    >
      {label}
    </Text>
    <Flex mt={2} flexDirection={row ? 'row' : 'column'}>
      {children}
    </Flex>
  </>
)

export default EventsList
