import React, { useState } from 'react'
import styled from 'styled-components/macro'
import get from 'lodash.get'
import { useAuthState } from 'react-firebase-hooks/auth'

import firebase, { auth } from '../../utilities/firebase'
import facebookLogo from '../../assets/img/facebook-logo.png'
import googleLogo from '../../assets/img/google-logo.svg'

import { colors, breakpoints } from '../../constants/theme'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: ${breakpoints[0]}) {
    align-items: center;
    flex-direction: row;  
  }
`

const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  color: white;
  background-color: ${props => (props.google ? '#4285F4' : '#395795')};
  padding: ${props => (props.google ? 0 : 10)}px;
  padding-right: 10px;
  box-shadow: 0px 1px 1px 0px rgba(209,209,209,1);

  img {
    margin-right: 10px;
    height: ${props => (props.google ? 39 : 20)}px;
  }

  @media (min-width: ${breakpoints[0]}) {
    align-items: center;
    flex-direction: row;
    margin: 0 20px 0 0;
  }
`

const Error = styled.p`
  color: ${colors.error};
`

const SocialButton = () => {
  const [user, loading] = useAuthState(auth)
  const [googleLinked, setGoogleLinked] = useState(!!get(user, 'providerData', []).find(d => d.providerId === 'google.com'))
  const [facebookLinked, setFacebookLinked] = useState(!!get(user, 'providerData', []).find(d => d.providerId === 'facebook.com'))
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(false)

  if (loading) return null

  const link = (provider) => {
    setErrorMessage('')
    setDisabled(true)

    auth.currentUser.linkWithPopup(provider).then((result) => {
      if (get(result, 'additionalUserInfo.providerId') === 'google.com') {
        setGoogleLinked(true)
      } else {
        setFacebookLinked(true)
      }
      setDisabled(false)
    }).catch((error) => {
      setErrorMessage(error.toString())
      setDisabled(false)
    })
  }

  return (
    <>
      <Wrapper>
        <Button
          type="button"
          onClick={() => link(new firebase.auth.GoogleAuthProvider())}
          disabled={disabled || googleLinked}
          google
        >
          <img src={googleLogo} alt="link google" />
          {googleLinked ? 'Google Linked' : 'Link Google'}
        </Button>
        <Button
          type="button"
          onClick={() => link(new firebase.auth.FacebookAuthProvider())}
          disabled={disabled || facebookLinked}
        >
          <img src={facebookLogo} alt="link facebook" />
          {facebookLinked ? 'Facebook Linked' : 'Link Facebook'}
        </Button>
      </Wrapper>
      <Error>{errorMessage}</Error>
    </>
  )
}

export default SocialButton
