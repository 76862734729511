import React from 'react'

import Section from '../Section'
import General from './General'
import Credentials from './Credentials'
import Account from './Account'
import CTA from './CTA'

const Settings = ({ admin, me }) => (
  <>
    <Section
      heading="Profile"
      text="Your personal account information and preferences"
    >
      <General admin={admin} me={me} />
      <Credentials admin={admin} me={me} />
    </Section>
    <Section
      heading="Account"
      text="Information about your account"
    >
      <Account admin={admin} />
      <CTA admin={admin} />
    </Section>
  </>
)

export default Settings
