import { css } from 'styled-components/macro'

const shake = css`
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

const tada = css`
  .element-animation{
    animation: animationFrames linear 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFrames linear 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFrames linear 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFrames linear 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFrames linear 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
  }

  @keyframes animationFrames{
    0% {
      transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
    }
    10% {
      transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    20% {
      transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    30% {
      transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    40% {
      transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    50% {
      transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    60% {
      transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    70% {
      transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    80% {
      transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    90% {
      transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    100% {
      transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
    }
  }

  @-moz-keyframes animationFrames{
    0% {
      -moz-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
    }
    10% {
      -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    20% {
      -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    30% {
      -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    40% {
      -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    50% {
      -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    60% {
      -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    70% {
      -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    80% {
      -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    90% {
      -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    100% {
      -moz-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
    }
  }

  @-webkit-keyframes animationFrames {
    0% {
      -webkit-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
    }
    10% {
      -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    20% {
      -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    30% {
      -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    40% {
      -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    50% {
      -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    60% {
      -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    70% {
      -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    80% {
      -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    90% {
      -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    100% {
      -webkit-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
    }
  }

  @-o-keyframes animationFrames {
    0% {
      -o-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
    }
    10% {
      -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    20% {
      -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    30% {
      -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    40% {
      -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    50% {
      -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    60% {
      -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    70% {
      -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    80% {
      -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    90% {
      -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    100% {
      -o-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
    }
  }

  @-ms-keyframes animationFrames {
    0% {
      -ms-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
    }
    10% {
      -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    20% {
      -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
    }
    30% {
      -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    40% {
      -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    50% {
      -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    60% {
      -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    70% {
      -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    80% {
      -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
    }
    90% {
      -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
    }
    100% {
      -ms-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
    }
  }
`

export {
  shake,
  tada,
}
