import React from 'react'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import groupBy from 'lodash.groupby'
import moment from 'moment'
import sortBy from 'lodash.sortby'

import randomColor from '../../utilities/randomColor'

const SimpleLineChart = ({ events = [] }) => {
  // group all events by day - by DAY not timestamp
  const byDay = groupBy(sortBy(events, 'timestamp').map(e => ({
    ...e,
    // get just the month/day, moment does not directly support so use regex
    timestamp: moment(e.timestamp).format('L').replace(RegExp(`[^.]?${moment().format('YYYY')}.?`), ''),
  })), 'timestamp')

  // group all events by geofence
  const byGeofence = groupBy(events, 'geofence_id')

  // build a record for each day with keys for each geofence
  const data = []
  Object.keys(byDay).forEach((key) => {
    const record = { day: key }
    Object.keys(groupBy(byDay[key], 'geofence_id')).forEach((key2) => {
      record[byGeofence[key2][0].geofence.name] = (byDay[key].filter(d => d.geofence_id === key2) || []).length
    })
    data.push(record)
  })

  if (!data.length) return null

  // create a `Line` for each geofence
  const lines = []
  Object.keys(byGeofence).forEach((key) => {
    lines.push(
      <Line
        key={key}
        type="monotone"
        dataKey={byGeofence[key][0].geofence.name}
        stroke={randomColor({ luminosity: 'dark' })}
        activeDot={{ r: 8 }}
      />,
    )
  })

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
    >
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Legend />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default SimpleLineChart
