import React from 'react'
import { Text } from 'rebass'

import Container from '../Container'

const App = ({ noMargin }) => (
  noMargin
    ? <Text>Oops, page does not exist!</Text>
    : (
      <Container py={4}>
        <Text>Oops, page does not exist!</Text>
      </Container>
    )
)

export default App
